import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaChip } from "@/components/general/GaChip";
import { Interactions } from "@/components/ask-getabstract/utils/store";
import type { Store } from "@/common/storeUtils";
import type { Dict } from "@utils/dictUtils";
import type { DeepReadonly } from "vue";

export function MailFeedback(props: { question: string; questionUuid: string }): GaVueComponent {
  const { t } = useI18n();

  return (
    <a href={`mailto:feedback@getabstract.com?subject=${encodeURIComponent(`[uuid:${props.questionUuid}]: I want to give Beta feedback`)}&body=${encodeURIComponent(`I asked the question: [question:${props.question}].`)}`} target="_blank">
      <i class="ico-envelope"></i>
      {t("search:askGa.feedback.wantToGiveFeedback")}
    </a>
  );
}

export function Feedback(props: { feedbackByUuid: DeepReadonly<Dict<boolean>>; feedbackStore: Store<Dict<boolean>>; question: string; questionUuid: string; showMailFeedbackLink?: boolean; border?: boolean }): GaVueComponent {
  const { t } = useI18n();

  function createGiveFeedbackHandler(isPositive: boolean) {
    return () => Interactions.giveFeedback(props.feedbackStore, props.questionUuid, isPositive);
  }

  function feedbackIsPositive(questionUuid: string): boolean | null {
    return props.feedbackByUuid[questionUuid] ?? null;
  }

  return (
    <div class="d-grid gap-3">
      <h5 class="m-0">{t("search:askGa.feedback.title")}</h5>
      <div class="getabstract-ai__feedback">
        <GaChip icon="ico-thumbs-up" variant="success" border={props.border} type="radio" name="gaai-feedback" changeHandler={createGiveFeedbackHandler(true)} active={feedbackIsPositive(props.questionUuid) === true}>
          {t("search:askGa.feedback.helpful")}
        </GaChip>
        <GaChip icon="ico-thumbs-down" variant="danger" border={props.border} type="radio" name="gaai-feedback" changeHandler={createGiveFeedbackHandler(false)} active={feedbackIsPositive(props.questionUuid) === false}>
          {t("search:askGa.feedback.notHelpful")}
        </GaChip>
        {props.showMailFeedbackLink === true ? <MailFeedback question={props.question} questionUuid={props.questionUuid} /> : null}
      </div>
    </div>
  );
}
