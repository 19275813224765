import { type DeepReadonly, defineComponent, onMounted, type PropType } from "vue";
import { Language } from "@utils/type/type";
import { GaFormFieldInputSelectExperimental, type SelectOption } from "@/components/form/GaFormFieldInputSelect";
import type { GaVueComponent } from "@/common/vueUtils";
import { PushFrequency, type WarmupV2EditForm, type WarmupV2RelayType, type WarmupV2WidgetProps } from "@newgenerated/shared/schema";
import "@/components/warmup/warmupV2.scss";
import { createStore, type Store } from "@/common/storeUtils";
import { type TranslateFunction, useI18n } from "@/i18n/i18nSetup";
import { getCountryListViews } from "@utils/vue-migration/api/countryApi";
import { GaFormFieldInputTextExperimental } from "@/components/form/GaFormFieldInputText";
import { GaButton } from "@/components/general/GaButton";
import logo from "@/components/warmup/media/ga-logo-negative.svg";
import { ActionableCard } from "@/components/actionable/ActionableCard";
import { SummaryCardMini } from "@/components/cards/SummaryCardMini";
import defaultImage from "@/components/warmup/media/getinspired.png";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import { SummaryCard } from "@/components/cards/SummaryCard";
import { processWarmupV2EditForm } from "@generated/api/warmupV2ControllerApi";
import { getErrors, updateFormErrors, validate, type WarmupV2FormMeta } from "@/components/warmup/warmupV2Form";
import { GaFormFieldInputCheckboxExperimental } from "@/components/form/GaFormFieldInputCheckbox";
import { GaAlert } from "@/components/general/GaAlert";
import { renderJsonWithNumbersAsBigint } from "@utils/json";

type WarmupV2Options = {
  languages: SelectOption<Language>[];
  countries: SelectOption<string>[];
  frequencies: SelectOption<PushFrequency>[];
};

export type WarmupV2State = {
  editForm: WarmupV2EditForm;
  metaForm: WarmupV2FormMeta;
  options: WarmupV2Options;
};

export function getLanguageOptions(t: TranslateFunction): SelectOption<Language>[] {
  return Language.getValues().map((isoCode): SelectOption<Language> => ({ value: isoCode, label: t(Language.getTmsKey(isoCode)) }));
}

export function getPushFrequencyOptions(t: TranslateFunction): SelectOption<PushFrequency>[] {
  const tmsKeys: Record<PushFrequency, string> = {
    NONE: "warmup2:pushFrequency.NONE",
    DAILY: "warmup2:pushFrequency.DAILY",
    ONCE_A_WEEK: "warmup2:pushFrequency.ONCE_A_WEEK",
    TWICE_A_MONTH: "warmup2:pushFrequency.TWICE_A_MONTH",
    ONCE_A_MONTH: "warmup2:pushFrequency.ONCE_A_MONTH",
  };
  return PushFrequency.getValues().map(
    (frequency): SelectOption<PushFrequency> => ({
      value: frequency,
      label: t(tmsKeys[frequency]),
    }),
  );
}

export async function loadCountryOptions(store: Store<SelectOption<string>[]>): Promise<void> {
  const countryListView = await getCountryListViews();
  const countryOptions = countryListView.map(
    ({ countryCode, countryName }): SelectOption<string> => ({
      value: countryCode,
      label: countryName,
    }),
  );
  store.set(countryOptions);
}

function WarmupV2Form(props: { editForm: Store<WarmupV2EditForm>; formMetaStore: Store<WarmupV2FormMeta>; options: DeepReadonly<WarmupV2Options>; warmupV2Uuid: string; relayState: string }): GaVueComponent {
  const { t } = useI18n();
  const emailStore = props.editForm.sub("email");
  const languageStore = props.editForm.sub("language");
  const countryStore = props.editForm.sub("country");
  const frequencyStore = props.editForm.sub("frequency");
  const tacStore = props.editForm.sub("termsAndConditions");

  const changedFieldsStore = props.formMetaStore.sub("changedFields");

  // TODO: Make sure submit can not be done multiple times in a row (deduplication of processing)
  async function onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    const processResult = await processWarmupV2EditForm({ form: props.editForm.get(), warmupV2Uuid: props.warmupV2Uuid });

    updateFormErrors(props.formMetaStore, processResult);

    if (processResult.kind === "SUCCESS") {
      window.location.replace(URL.canParse(props.relayState) ? props.relayState : new URL("/", location.origin));
    }
  }

  async function onBlur(field: keyof WarmupV2EditForm): Promise<void> {
    const { kind, store } = changedFieldsStore.unpackUnion();
    if (kind !== "ALL") {
      store.sub("fields").set([...store.sub("fields").get(), field]);
    }
    const response = await validate(props.editForm.get);
    if (response.state === "SUCCESS") {
      const validateResult = response.value;
      updateFormErrors(props.formMetaStore, validateResult);
    }
  }

  const generalErrors = props.formMetaStore.sub("errors").sub("general").get();

  return (
    <form onSubmit={onSubmit}>
      <div class="ga-dynamic-grid" style="--ga-dynamic-grid--min-width: 17rem;">
        <GaFormFieldInputTextExperimental store={emailStore} type="email" onBlur={() => onBlur("email")} name="email" label={t("aspSubscribe:subscribe.form.email")} errors={getErrors(props.formMetaStore, "email")} disabled={true} />
        <GaFormFieldInputTextExperimental store={createStore("")} type="password" name="password" label={t("aspSubscribe:subscribe.form.password")} disabled={true} />
        <GaFormFieldInputSelectExperimental
          store={languageStore}
          options={props.options.languages}
          name="language"
          label={t("aspSubscribe:subscribe.form.preferredLanguage")}
          onBlur={() => onBlur("language")}
          errors={getErrors(props.formMetaStore, "language")}
        />
        <GaFormFieldInputSelectExperimental
          store={countryStore}
          options={props.options.countries}
          name="country"
          label={t("aspSubscribe:subscribe.form.country")}
          onBlur={() => onBlur("country")}
          errors={getErrors(props.formMetaStore, "country")}
        />
        <div class="mt-3">
          <GaFormFieldInputSelectExperimental
            store={frequencyStore}
            options={props.options.frequencies}
            name="frequency"
            label={t("warmup2:form.frequency.label")}
            onBlur={() => onBlur("frequency")}
            errors={getErrors(props.formMetaStore, "frequency")}
          />
        </div>
      </div>
      <div class="mt-5">
        <GaFormFieldInputCheckboxExperimental
          store={tacStore}
          labelHtml={t("warmup2:form.termsAndConditions.label", ["/terms-of-use", "/privacy-policy/corporate"])}
          name="tac"
          onBlur={() => onBlur("termsAndConditions")}
          errors={getErrors(props.formMetaStore, "termsAndConditions")}
        />
      </div>
      {generalErrors.length > 0 && changedFieldsStore.get().kind === "ALL" ? (
        <div class="my-3">
          <GaAlert variant="danger">
            <ul style="margin: 0;">
              {generalErrors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </GaAlert>
        </div>
      ) : null}
      <div class="mt-4">
        <GaButton type="submit">{t("warmup2:button.label")}</GaButton>
      </div>
    </form>
  );
}

export function WarmupV2Component(props: { store: Store<WarmupV2State>; relay: DeepReadonly<WarmupV2RelayType>; corporationName: string; warmupV2Uuid: string }): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class="warmupV2">
      <aside class="warmupV2-sidebar">
        <div class="text-center">
          <img src={logo} alt="getAbstract" width="182" height="30" />
        </div>
        <div class="warmupV2-sidebar-image">
          {props.relay.kind === "ACTIONABLE" ? <ActionableCard actionable={props.relay.actionable} /> : null}
          {props.relay.kind === "SUMMARY" ? (
            <div class="warmupV2-sidebar-image-summary">
              <SummaryCardMini summary={props.relay.summary} />
            </div>
          ) : null}
          {props.relay.kind === "DEFAULT" ? <img src={defaultImage} alt="" /> : null}
        </div>
        <span class="warmupV2-sidebar-slogan" vHtml={t("warmup2:slogan")} />
      </aside>
      <aside class="warmupV2-sidebar--mobile">
        {props.relay.kind === "ACTIONABLE" || props.relay.kind === "SUMMARY" ? <span vHtml={t("warmup2:slogan")} /> : null}
        {props.relay.kind === "ACTIONABLE" ? <ActionableCardMini actionable={props.relay.actionable} /> : null}
        {props.relay.kind === "SUMMARY" ? <SummaryCard summary={props.relay.summary} compact={true} /> : null}
      </aside>
      <main class="warmupV2-main">
        <h2>{t("warmup2:intro.heading", [props.corporationName])}</h2>
        <p>{t("warmup2:intro.subheading")}</p>
        <WarmupV2Form editForm={props.store.sub("editForm")} formMetaStore={props.store.sub("metaForm")} options={props.store.sub("options").get()} warmupV2Uuid={props.warmupV2Uuid} relayState={props.relay.relayState} />
      </main>
      <div style="grid-column: 1 / -1;">
        <br />
        <h2>EditForm</h2>
        <code>{renderJsonWithNumbersAsBigint(props.store.sub("editForm").get(), 2)}</code>
        <br />
        <h2>MetaForm</h2>
        <code>{renderJsonWithNumbersAsBigint(props.store.sub("metaForm").get(), 2)}</code>
      </div>
    </div>
  );
}

export const WarmupV2 = defineComponent({
  props: {
    warmupV2WidgetProps: {
      type: Object as PropType<WarmupV2WidgetProps>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { email, language, country, relayType, corporationName, warmupV2Uuid } = props.warmupV2WidgetProps;
    const store = createStore<WarmupV2State>({
      editForm: {
        email: email,
        language: language,
        country: country,
        frequency: "ONCE_A_WEEK",
        termsAndConditions: false,
      },
      metaForm: {
        errors: { general: [], fields: { additionalProperties: {} } },
        changedFields: { kind: "FIELDS", fields: [] },
      },
      options: {
        languages: getLanguageOptions(t),
        countries: [],
        frequencies: getPushFrequencyOptions(t),
      },
    });

    onMounted(async () => {
      await loadCountryOptions(store.sub("options").sub("countries"));
    });

    return () => <WarmupV2Component store={store} relay={relayType} corporationName={corporationName} warmupV2Uuid={warmupV2Uuid} />;
  },
});
