import type { ValidationErrorReasons, WarmupV2EditForm, WarmupV2ValidationResult } from "@newgenerated/shared/schema";
import memoize from "lodash/memoize";
import { validateWarmupV2EditForm } from "@generated/api/warmupV2ControllerApi";
import type { DeepReadonly } from "vue";
import { type Result, saveAsyncRequest } from "@/common/requestUtils";
import isEqual from "lodash/isEqual";
import type { Store } from "@/common/storeUtils";
import { renderJsonWithNumbersAsBigint } from "@utils/json";

export type WarmupV2FormMeta = {
  errors: ValidationErrorReasons;
  changedFields: { kind: "ALL" } | { kind: "FIELDS"; fields: string[] };
};

const mem = memoize(validateWarmupV2EditForm, renderJsonWithNumbersAsBigint);

export async function validate(getForm: () => DeepReadonly<WarmupV2EditForm>): Promise<Result<WarmupV2ValidationResult>> {
  return await saveAsyncRequest(mem, (prev) => isEqual(getForm(), prev), getForm());
}

export function getErrors(store: Store<WarmupV2FormMeta>, field: keyof WarmupV2EditForm): DeepReadonly<string[]> {
  const fieldErrorsStore = store.sub("errors").sub("fields").sub("additionalProperties");
  const changedFieldsStore = store.sub("changedFields");

  const fieldErrors = fieldErrorsStore.get()[field] ?? [];
  const changedFields = changedFieldsStore.get();
  if (changedFields.kind === "ALL" || changedFields.fields.includes(field)) {
    return fieldErrors;
  }
  return [];
}

export function updateFormErrors(store: Store<WarmupV2FormMeta>, validationResult: WarmupV2ValidationResult): void {
  if (validationResult.kind === "ERROR") {
    store.sub("changedFields").set({ kind: "ALL" });
    store.sub("errors").set(validationResult.reasons);
  } else {
    store.sub("errors").set({ general: [], fields: { additionalProperties: {} } });
  }
}
