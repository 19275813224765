// @ts-ignore
import { registerVueWidgetForJsp } from "@/Registry";
// @ts-ignore
import { defineComponent, type PropType } from "@/common/vueUtils";
// @ts-ignore
import { ActionablePage } from "@/components/actionable/actionable-page/ActionablePage";
// @ts-ignore
import { FullSearch } from "@/components/search/FullSearch";
// @ts-ignore
import { AskGetabstractWidget } from "@/components/ask-getabstract/AskGetabstract";
// @ts-ignore
import { AskGetabstractIframe } from "@/components/ask-getabstract/AskGetabstractIframe";
// @ts-ignore
import { AskGetabstractDemo } from "@/components/ask-getabstract/demo/AskGetabstractDemo";
// @ts-ignore
import { VerifaiCreatorPage } from "@/components/verifai/VerifaiCreatorPage";
// @ts-ignore
import { GaTopLayer } from "@/components/general/GaTopLayer";
// @ts-ignore
import { WarmupV2 } from "@/components/warmup/WarmupV2";
// @ts-ignore
import { WarmupV2Demo } from "@/components/warmup/demo/WarmupV2Demo";
// @ts-ignore
import { ModalDemo } from "@/components/general/demo/ModalDemo";
// @ts-ignore
import { ExperimentalGeneratorDemo } from "@/components/demo/ExperimentalGeneratorDemo";
// @ts-ignore
import { GaProgressCircleDemo } from "@/components/general/demo/GaProgressCircleDemo";
// @ts-ignore
import { SummaryCardDefaultDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { SummaryCardCompactDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { SummaryCardMiniDemo } from "@/components/cards/SummaryCardDemo";
// @ts-ignore
import { FullSearchAiDemoComponent } from "@/components/search/FullSearchAiDemoComponent";
// @ts-ignore
import type { ActionablePageProps, FullSearchProps, AskGetabstractProperties, VerifaiCreatorPageProps, WarmupV2WidgetProps, SummaryCardDemoProps } from "@newgenerated/shared/schema";

const ActionablePageWidget = defineComponent({
   props: {
       actionablePageProps: {
           type: Object as PropType<ActionablePageProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <ActionablePage actionablePageProps={ props.actionablePageProps } />;
   },
});
registerVueWidgetForJsp("ActionablePage", ActionablePageWidget);

const FullSearchWidget = defineComponent({
   props: {
       fullSearchProps: {
           type: Object as PropType<FullSearchProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <FullSearch fullSearchProps={ props.fullSearchProps } />;
   },
});
registerVueWidgetForJsp("FullSearch", FullSearchWidget);

const AskGetabstractWidgetWidget = defineComponent({
   props: {
       askGetabstractProperties: {
           type: Object as PropType<AskGetabstractProperties>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <AskGetabstractWidget askGetabstractProperties={ props.askGetabstractProperties } />;
   },
});
registerVueWidgetForJsp("AskGetabstractWidget", AskGetabstractWidgetWidget);

const AskGetabstractIframeWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <AskGetabstractIframe />;
   },
});
registerVueWidgetForJsp("AskGetabstractIframe", AskGetabstractIframeWidget);

const AskGetabstractDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <AskGetabstractDemo />;
   },
});
registerVueWidgetForJsp("AskGetabstractDemo", AskGetabstractDemoWidget);

const VerifaiCreatorPageWidget = defineComponent({
   props: {
       verifaiCreatorPageProps: {
           type: Object as PropType<VerifaiCreatorPageProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <VerifaiCreatorPage verifaiCreatorPageProps={ props.verifaiCreatorPageProps } />;
   },
});
registerVueWidgetForJsp("VerifaiCreatorPage", VerifaiCreatorPageWidget);

const GaTopLayerWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <GaTopLayer />;
   },
});
registerVueWidgetForJsp("GaTopLayer", GaTopLayerWidget);

const WarmupV2Widget = defineComponent({
   props: {
       warmupV2WidgetProps: {
           type: Object as PropType<WarmupV2WidgetProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <WarmupV2 warmupV2WidgetProps={ props.warmupV2WidgetProps } />;
   },
});
registerVueWidgetForJsp("WarmupV2", WarmupV2Widget);

const WarmupV2DemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <WarmupV2Demo />;
   },
});
registerVueWidgetForJsp("WarmupV2Demo", WarmupV2DemoWidget);

const ModalDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <ModalDemo />;
   },
});
registerVueWidgetForJsp("ModalDemo", ModalDemoWidget);

const ExperimentalGeneratorDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <ExperimentalGeneratorDemo />;
   },
});
registerVueWidgetForJsp("ExperimentalGeneratorDemo", ExperimentalGeneratorDemoWidget);

const GaProgressCircleDemoWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <GaProgressCircleDemo />;
   },
});
registerVueWidgetForJsp("GaProgressCircleDemo", GaProgressCircleDemoWidget);

const SummaryCardDefaultDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<SummaryCardDemoProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <SummaryCardDefaultDemo summaryCardDemoProps={ props.summaryCardDemoProps } />;
   },
});
registerVueWidgetForJsp("SummaryCardDefaultDemo", SummaryCardDefaultDemoWidget);

const SummaryCardCompactDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<SummaryCardDemoProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <SummaryCardCompactDemo summaryCardDemoProps={ props.summaryCardDemoProps } />;
   },
});
registerVueWidgetForJsp("SummaryCardCompactDemo", SummaryCardCompactDemoWidget);

const SummaryCardMiniDemoWidget = defineComponent({
   props: {
       summaryCardDemoProps: {
           type: Object as PropType<SummaryCardDemoProps>,
           required: true,
       },
   },
   setup(props) {
       // @ts-ignore
       return () => <SummaryCardMiniDemo summaryCardDemoProps={ props.summaryCardDemoProps } />;
   },
});
registerVueWidgetForJsp("SummaryCardMiniDemo", SummaryCardMiniDemoWidget);

const FullSearchAiDemoComponentWidget = defineComponent({
   setup() {
       // @ts-ignore
       return () => <FullSearchAiDemoComponent />;
   },
});
registerVueWidgetForJsp("FullSearchAiDemoComponent", FullSearchAiDemoComponentWidget);

