import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { MailFeedback } from "@/components/ask-getabstract/uiFragments/Feedback";
import type { DeepReadonly } from "vue";
import type { ComponentUiState, Failures } from "@/components/ask-getabstract/utils/store";
import { GaAlert } from "@/components/general/GaAlert";

export function ErrorMessage(props: { question: string; questionUuid: string; status: "ERROR_NO_ANSWER" | "ERROR_GENERAL" }): GaVueComponent {
  const { t } = useI18n();

  const { title, description } = (() => {
    switch (props.status) {
      case "ERROR_NO_ANSWER": {
        return {
          title: t("search:askGa.error.noAnswer.title"),
          description: t("search:askGa.error.noAnswer.description"),
        };
      }
      case "ERROR_GENERAL": {
        return {
          title: t("search:askGa.error.general.title"),
          description: t("search:askGa.error.general.description"),
        };
      }
    }
  })();

  return (
    <div class="text-center">
      <h3>{title}</h3>
      <p>{description}</p>
      <MailFeedback question={props.question} questionUuid={props.questionUuid} />
    </div>
  );
}

export function ErrorMessages(props: { uiState: DeepReadonly<ComponentUiState> }): GaVueComponent | null {
  if (props.uiState.kind !== "IDLE") {
    return null;
  }

  if (props.uiState.value.current.status === "SUCCESS") {
    return null;
  }

  return <ErrorMessage status={props.uiState.value.current.status} question={props.uiState.value.current.question} questionUuid={props.uiState.value.current.questionUuid} />;
}

export function FailureAlerts(props: { failures: DeepReadonly<Failures>; dismissAlert: (failureType: keyof Failures) => void }): GaVueComponent {
  const { t } = useI18n();
  return (
    <>
      {props.failures.feedback ? (
        <GaAlert variant="danger" closeAlert={() => props.dismissAlert("feedback")}>
          {t("search:askGa.alert.failed.feedback")}
        </GaAlert>
      ) : null}
      {props.failures.bookmark ? (
        <GaAlert variant="danger" closeAlert={() => props.dismissAlert("bookmark")}>
          {t("search:askGa.alert.failed.bookmarking")}
        </GaAlert>
      ) : null}
    </>
  );
}
