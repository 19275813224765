import { type DeepReadonly, nextTick, ref } from "vue";
import { useI18n } from "@/i18n/i18nSetup";
import type { FormFilter, FullSearchProps, SearchFiltersForm } from "@newgenerated/shared/schema";
import { assert, assertIsDefined } from "@utils/assertion";
import type { SelectOption } from "@/components/form/GaFormFieldInputSelect";
import { simpleBackgroundWorker } from "@/components/ask-getabstract/utils/backgroundWorker";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/ask-getabstract/utils/animationUtils";
import { STREAMING_SPEED_UP_FACTOR, STREAMING_TOKEN_PER_SEC, WAITING_INTERVAL_IN_MS } from "@/components/ask-getabstract/utils/store";
import { defaultAmount, type FilterType, filterTypes, type FullSearchStoreApiMethods, type SearchStoreActions, type SearchStoreState } from "@/components/search/fullSearchStoreTypes";

export function isFilterType(value: string): value is FilterType {
  return filterTypes.includes(value as FilterType);
}

function getFilter(formFilters: FormFilter[], filterType: FilterType): FormFilter | null {
  return formFilters.find((filter) => filter.name === filterType) ?? null;
}

export function getActiveFilterValues(formFilters: FormFilter[], filterType: FilterType): string[] {
  const filter = getFilter(formFilters, filterType);
  return filter !== null ? filter.activeValues : [];
}

function convertToNumbers(input: string[]): number[] {
  return input.map((value) => {
    const number = parseInt(value);
    if (Number.isNaN(number)) {
      throw Error("could not parse number");
    }
    return number;
  });
}

function convertToSearchFiltersForm(formFilters: FormFilter[], searchTerm: string, summariesPage: number): SearchFiltersForm {
  const audioFilter = getActiveFilterValues(formFilters, "audioFormFilter").at(0);
  assert(audioFilter === "true" || audioFilter === "false" || audioFilter === undefined);
  return {
    qualityFormFilter: getActiveFilterValues(formFilters, "qualityFormFilter"),
    ratingFormFilter: convertToNumbers(getActiveFilterValues(formFilters, "ratingFormFilter")),
    sourceFormFilter: getActiveFilterValues(formFilters, "sourceFormFilter"),
    languageFormFilter: getActiveFilterValues(formFilters, "languageFormFilter"),
    audioFormFilter: audioFilter === "true",
    publicationDateFormFilter: convertToNumbers(getActiveFilterValues(formFilters, "publicationDateFormFilter")),
    query: searchTerm,
    summariesPage: summariesPage,
  };
}

function applyQueryParamsToUrl(formFilters: FormFilter[]): void {
  const queryParams = new URLSearchParams(window.location.search);
  formFilters.map((filter) => {
    queryParams.delete(filter.name);
    filter.activeValues.forEach((value) => {
      if (!(filter.name === "audioFormFilter" && filter.activeValues.at(0) === "false")) {
        queryParams.append(filter.name, value);
      }
    });
  });
  history.replaceState(null, "", "?" + queryParams.toString());
}

function readQueryParametersFromUrl(formFilters: FormFilter[]): FormFilter[] {
  const queryParams = new URLSearchParams(window.location.search);
  return formFilters.map((filter) => {
    const values = queryParams.getAll(filter.name);
    return {
      ...filter,
      activeValues: values.length > 0 ? values : filter.activeValues,
    };
  });
}

export function createStore(
  props: FullSearchProps,
  apiMethods: FullSearchStoreApiMethods,
): {
  state: () => DeepReadonly<SearchStoreState>;
  actions: SearchStoreActions;
} {
  const { t } = useI18n();
  const qualityFormFilter = getFilter(props.initialFormFilters, "qualityFormFilter");
  const state = ref<SearchStoreState>({
    contentTypeProps: [
      { discriminator: "SUMMARY", title: t("general:summaries"), amountToShow: defaultAmount["SUMMARY"], items: [] },
      { discriminator: "ACTIONABLE", title: t("general:actionables"), amountToShow: defaultAmount["ACTIONABLE"], items: [] },
      { discriminator: "CUSTOMPAGE", title: t("customPage:customPages"), amountToShow: defaultAmount["CUSTOMPAGE"], items: [] },
      { discriminator: "CHANNEL", title: t("general:channels"), amountToShow: defaultAmount["CHANNEL"], items: [] },
    ],
    searchTerm: "",
    selectedContentType: "ALL",
    status: "LOADING",
    formFilters: readQueryParametersFromUrl(props.initialFormFilters),
    filtersExtended: false,
    multiSelectProps: {
      options: qualityFormFilter !== null ? qualityFormFilter.options : [],
      searchTerm: "",
      selectedOptions: [],
      showSearch: false,
    },
    summariesPaging: {
      page: 0,
      totalCount: BigInt(0),
    },
    params: new URLSearchParams(),
    filterOptions: props.initialFormFilters.map((filter) => {
      return { filterName: filter.name, isOpen: false };
    }),
    aiState: {
      feature: "NOT_AVAILABLE",
    },
  });

  apiMethods.hasFeature("ASK_GETABSTRACT").then((featureAvailable) => {
    if (featureAvailable) {
      state.value.aiState = {
        feature: "AVAILABLE",
        displayFullAnswer: false,
        uiState: { kind: "INITIAL" },
      };

      void simpleBackgroundWorker(
        {
          streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
          streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
          analyticsEventVariant: "qa_triggered_from_search",
        },
        {
          getInteractions: () => state.value,
          updateUi: (newState) => {
            assert(state.value.aiState.feature === "AVAILABLE");
            state.value = { ...state.value, aiState: { ...state.value.aiState, uiState: newState } };
          },
          initQuestionAnswer: apiMethods.createQuestion,
          getQuestionAnswers: apiMethods.getQuestionAnswers,
          delay: () => delay(WAITING_INTERVAL_IN_MS),
          isAborted: () => false,
          startTimer,
        },
      );
    }
  });

  const actions: SearchStoreActions = {
    onChange(options: SelectOption<string>[]): void {
      state.value = { ...state.value, multiSelectProps: { ...state.value.multiSelectProps, selectedOptions: options } };
      actions.updateFilter(
        "qualityFormFilter",
        options.map((o) => o.value),
      );
    },
    onSearchTermChange(value: string): void {
      state.value = { ...state.value, multiSelectProps: { ...state.value.multiSelectProps, searchTerm: value } };
    },
    toggleSearch(): void {
      state.value = {
        ...state.value,
        multiSelectProps: {
          ...state.value.multiSelectProps,
          showSearch: !state.value.multiSelectProps.showSearch,
        },
      };
    },
    search: async (softReload): Promise<void> => {
      state.value = { ...state.value, status: softReload === true ? "LOADMORE" : "LOADING", searchTerm: props.query ?? "" };
      try {
        applyQueryParamsToUrl(state.value.formFilters);
        const result = await apiMethods.search(convertToSearchFiltersForm(state.value.formFilters, state.value.searchTerm, state.value.summariesPaging.page));
        const contentTypes = state.value.contentTypeProps.map((cat) => {
          switch (cat.discriminator) {
            case "CHANNEL":
              return { ...cat, items: result.channels };
            case "SUMMARY":
              return { ...cat, items: result.summaries };
            case "CUSTOMPAGE":
              return { ...cat, items: result.customPages };
            case "ACTIONABLE":
              return { ...cat, items: result.actionables };
          }
        });
        state.value = {
          ...state.value,
          contentTypeProps: contentTypes,
          formFilters: result.formFilters,
          summariesPaging: result.summariesPaging,
          params: new URLSearchParams(result.downloadSourceParams.additionalProperties),
          status: "IDLE",
        };
      } catch (_) {
        state.value = { ...state.value, status: "ERROR" };
      }
    },
    selectContentType: (contentType) => {
      state.value = { ...state.value, selectedContentType: contentType };
      void nextTick(() => {
        scrollTo({ top: 0 });
      });
    },
    updateFilter: (filterName: FilterType, newActiveValues: string[]) => {
      const formFilters = [...state.value.formFilters];
      const filter = getFilter(state.value.formFilters, filterName);
      assertIsDefined(filter);
      filter.activeValues = newActiveValues;
      state.value = { ...state.value, formFilters: formFilters };
    },
    resetFilter: (filterType) => {
      const formFilters = [...state.value.formFilters];
      const filter = getFilter(formFilters, filterType);
      assertIsDefined(filter);
      filter.activeValues = [];
      state.value = { ...state.value, formFilters };
      if (filter.name === "qualityFormFilter") {
        actions.onChange([]);
      }
    },
    toggleFilters: () => {
      state.value = { ...state.value, filtersExtended: !state.value.filtersExtended };
    },
    resetAllFilters: () => {
      state.value.formFilters.forEach((filter) => {
        assert(isFilterType(filter.name));
        actions.resetFilter(filter.name);
      });
      void actions.search();
    },
    loadMoreSummaries: async () => {
      state.value = { ...state.value, summariesPaging: { ...state.value.summariesPaging, page: state.value.summariesPaging.page + 1 } };
      await actions.search(true);
    },
    toggleSingleFilter: (filterName: string) => {
      const filterOptions = [...state.value.filterOptions];
      const filterOption = filterOptions.find((value) => value.filterName === filterName);
      assertIsDefined(filterOption);
      filterOption.isOpen = !filterOption.isOpen;
      state.value = { ...state.value, filterOptions: filterOptions };
    },
    showFullAiAnswer: () => {
      assert(state.value.aiState.feature === "AVAILABLE");
      state.value = { ...state.value, aiState: { ...state.value.aiState, displayFullAnswer: true } };
    },
  };
  if (props.query !== null && props.query !== "") {
    void actions.search();
  }
  return {
    state: () => state.value,
    actions: actions,
  };
}
